import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import '../App.css';

function SitePage() {
    const { siteid, sitename } = useParams(); // Get siteid from the URL
    const location = useLocation();  // Access the location object
    const queryParams = new URLSearchParams(location.search);
    const score = queryParams.get('score');  // Get the 'score' query parameter

    return (
        <div className="App-header">
            <h1>Hello, this is the page for site: {sitename} - {siteid} (Score: {score})</h1>
        </div>
    );
}

export default SitePage;