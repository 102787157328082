import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import '../styles/main-table.css'
import { fetchAuthSession } from 'aws-amplify/auth'
import { jwtDecode } from 'jwt-decode';


// Imports the Amplify library from 'aws-amplify' package. This is used to configure your app to interact with AWS services.
import { Amplify } from 'aws-amplify';

// Imports the Authenticator and withAuthenticator components from '@aws-amplify/ui-react'.
// Authenticator is a React component that provides a ready-to-use sign-in and sign-up UI.
// withAuthenticator is a higher-order component that wraps your app component to enforce authentication.
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';

// Imports the default styles for the Amplify UI components. This line ensures that the authenticator looks nice out of the box.
import '@aws-amplify/ui-react/styles.css';

// Imports the awsExports configuration file generated by the Amplify CLI. This file contains the AWS service configurations (like Cognito, AppSync, etc.) specific to your project.
import awsExports from '../aws-exports';

// Imports the Quiz component from Quiz.js for use in this file.
// import Quiz from './Quiz';

// Configures the Amplify library with the settings from aws-exports.js, which includes all the AWS service configurations for this project.
Amplify.configure(awsExports);

function Home() {

    const [selectedPeriod, setSelectedPeriod] = useState("[25,25]"); // Default to Period 
    const [tickets, setTickets] = useState([]);
    const [ticketsgrouped, setGroupedTickets] = useState([]);
    const [filteredticketsgrouped, setFilteredGroupedTickets] = useState([]);
    const [sites, setSites] = useState([]);
    const [sitesdetailed, setSitesDetailed] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState(null);
    const [isWeekday, setIsWeekday] = useState(null);
    const [mainSites, setmainSites] = useState([]);
    const [siteRegulations, setSiteRegulations] = useState([]);
    const [email, setEmail] = useState(null);
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [errorLocation, setErrorLocation] = useState(null);
    const [userAreas, setUserAreas] = useState([]);
    const [uniqueAreas, setUniqueAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState("All");
    const [loading, setLoading] = useState(true); // State to track loading status
    const [boostedSites, setBoostedSites] = useState([]); // New state for boosted sites


    // Fetch authentication token
    useEffect(() => {
        const fetchToken = async () => {
            try {
                const session = await fetchAuthSession();
                const idToken = session.tokens?.idToken?.toString();

                if (idToken) {
                    const decodedToken = jwtDecode(idToken);
                    const email = decodedToken.email; // Assuming 'email' is the key for the user's email in the token
                    setEmail(email);
                }
            } catch (error) {
                console.error("Error fetching auth token:", error);
            }
        };

        fetchToken();
    }, []); // Run once on component mount

    // Handle geolocation
    useEffect(() => {
        const handleGetLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLocation({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        });
                        setErrorLocation(null);
                    },
                    (error) => {
                        setErrorLocation(error.message);
                    }
                );
            } else {
                setErrorLocation("Geolocation is not supported by this browser.");
            }
        };

        handleGetLocation();
    }, []); // Run once on component mount

    const calculatePeriodAndDay = useCallback(() => {
        const now = new Date();
        const hours = now.getHours();
        const day = now.getDay(); // 0 = Sunday, 6 = Saturday

        // Determine if it's a weekday or weekend
        const newIsWeekday = day >= 1 && day <= 5; // Monday to Friday

        // Determine the current period
        // let newPeriod = [hours, hours + 1]
        // newPeriod = JSON.stringify(newPeriod)
        let newPeriod = "";
        if (hours >= 0 && hours < 6) {
            newPeriod = "[0,6]"; // 12am - 6am
        } else if (hours >= 6 && hours < 9) {
            newPeriod = "[6,9]"; // 6am - 9am
        } else if (hours >= 9 && hours < 12) {
            newPeriod = "[9,12]"; // 9am - 12pm
        } else if (hours >= 12 && hours < 17) {
            newPeriod = "[12,17]"; // 12pm - 5pm
        } else if (hours >= 17 && hours < 20) {
            newPeriod = "[17,20]"; // 5pm - 8pm
        } else if (hours >= 20 && hours <= 23) {
            newPeriod = "[20,24]"; // 8pm - 12am
        }
        // Update state only if values have changed
        if (newIsWeekday !== isWeekday) {
            // console.log('newIsWeekday', newIsWeekday)
            setIsWeekday(newIsWeekday);
        }
        if (newPeriod !== currentPeriod) {
            // console.log('newPeriod', newPeriod)
            setCurrentPeriod(newPeriod);
        }
    }, [isWeekday, currentPeriod]); // Add dependencies

    useEffect(() => {
        // Calculate the period and day immediately on load
        calculatePeriodAndDay();

        // Set up interval to update every minute
        const interval = setInterval(calculatePeriodAndDay, 60000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [calculatePeriodAndDay]); // Dependency on useCallback function

    useEffect(() => {
        // const areas = ['West Sussex (BN, RH)', 'London SW'];
        const areas = ['All'];
        setUserAreas(areas)
    }, []); // Run once on component mount

    // Fetch data (tickets and sites) and join
    useEffect(() => {
        if (userAreas.length !== 0) {
            const fetchSitesDetailedData = async () => {
                console.log("Fetching sites detailed data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    // Fetch tickets and sites concurrently
                    const [sitesdetailedResponse] = await Promise.all([
                        fetch("https://api.npcpatrolplan.com/api/sitesdetailed/?format=json", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                areas: userAreas,           // Pass the variable directly
                            }),
                        }),
                    ]);
                    // Check for HTTP errors before parsing JSON
                    if (!sitesdetailedResponse.ok) {
                        throw new Error(
                            `Error fetching data: SitesDetailed(${sitesdetailedResponse.status})`
                        );
                    }

                    const sitesDetailedResult = await sitesdetailedResponse.json();

                    if (Array.isArray(sitesDetailedResult)) {
                        const uniqueAreas = [...new Set(sitesDetailedResult.map((site) => site.areaname))];
                        setUniqueAreas(uniqueAreas);
                        setSitesDetailed(sitesDetailedResult);
                    } else {
                        setSitesDetailed([]);
                        setUniqueAreas([]);
                    }
                } catch (error) {
                    console.error("Error fetching sites detailed data:", error);
                }
            };
            fetchSitesDetailedData();
        }
    }, [userAreas]); // Debounce fetch calls

    // Fetch data (tickets and sites) and join
    useEffect(() => {
        if (sitesdetailed.length !== 0) {
            const siteIds = sitesdetailed.map(site => site.id);
            const fetchSitesData = async () => {
                console.log("Fetching sites data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    // Fetch tickets and sites concurrently
                    const [sitesResponse] = await Promise.all([
                        fetch("https://api.npcpatrolplan.com/api/sites/?format=json", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                siteids: siteIds,           // Pass the variable directly
                            }),
                        }),
                    ]);
                    // Check for HTTP errors before parsing JSON
                    if (!sitesResponse.ok) {
                        throw new Error(
                            `Error fetching data: Sites(${sitesResponse.status})`
                        );
                    }

                    const sitesResult = await sitesResponse.json();

                    if (Array.isArray(sitesResult)) {
                        setSites(sitesResult);
                    } else {
                        setSites([]);
                    }
                } catch (error) {
                    console.error("Error fetching sites data:", error);
                }
            };
            fetchSitesData();
        }
    }, [sitesdetailed]); // Debounce fetch calls 

    // Fetch data (tickets and sites) and join
    useEffect(() => {
        if (isWeekday !== null && selectedPeriod.length !== 0 && currentPeriod.length !== 0 && sitesdetailed.length !== 0 && sites.length !== 0) {
            const siteIds = sitesdetailed.map(site => site.id);
            const fetchTicketsData = async () => {
                console.log("Fetching tickets data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    // Fetch tickets and sites concurrently
                    const [ticketsResponse] = await Promise.all([
                        fetch("https://api.npcpatrolplan.com/api/tickets/grouped/?format=json", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({
                                isWeekday: true,           // Pass the variable directly
                                currentPeriod: selectedPeriod === "[25,25]" ? JSON.parse(currentPeriod) : JSON.parse(selectedPeriod),
                                siteids: siteIds
                            }),
                        }),
                    ]);
                    // Check for HTTP errors before parsing JSON
                    if (!ticketsResponse.ok) {
                        throw new Error(
                            `Error fetching data: Tickets(${ticketsResponse.status})`
                        );
                    }

                    const ticketsResult = await ticketsResponse.json();

                    if (Array.isArray(ticketsResult)) {
                        const groupedTicketsArray = groupBySite(ticketsResult);
                        const joinedData = joinTicketsWithRegulations(
                            groupedTicketsArray,
                            sites,
                            sitesdetailed
                        )
                        // Join Boost with groupedTickets

                        setGroupedTickets(joinedData);
                    } else {
                        setGroupedTickets([]);
                    }
                } catch (error) {
                    console.error("Error fetching tickets data:", error);
                }
            };
            fetchTicketsData();
        }
    }, [isWeekday, currentPeriod, selectedPeriod, sites, sitesdetailed]); // Debounce fetch calls

    // Fetch regulations data when sitesdetailed changes
    useEffect(() => {
        if (sitesdetailed.length !== 0) {
            const siteIds = sitesdetailed.map((site) => site.id);
            const fetchRegulationsData = async () => {
                console.log("Fetching regulations data...");
                try {
                    const session = await fetchAuthSession();
                    const accessToken = session.tokens?.accessToken?.toString();

                    if (!accessToken) {
                        console.error("No token available");
                        return;
                    }

                    const sitesRegulationsResponse = await fetch(
                        "https://api.npcpatrolplan.com/api/sites/regulations/?format=json",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${accessToken}`,
                            },
                            body: JSON.stringify({ siteids: siteIds }),
                        }
                    );

                    if (!sitesRegulationsResponse.ok) {
                        throw new Error(
                            `Error fetching data: Regulations(${sitesRegulationsResponse.status})`
                        );
                    }

                    const sitesRegulationsResults = await sitesRegulationsResponse.json();
                    if (Array.isArray(sitesRegulationsResults)) {
                        setSiteRegulations(sitesRegulationsResults);
                    } else {
                        setSiteRegulations([]);
                        setBoostedSites([]);
                    }
                } catch (error) {
                    console.error("Error fetching regulations data:", error);
                }
            };

            fetchRegulationsData();
        } else {
            setSiteRegulations([]); // Clear regulations if sitesdetailed is empty
        }
    }, [sitesdetailed]); // Dependency includes checkAndUpdateBoost

    useEffect(() => {
        if (selectedArea === "All") {
            setFilteredGroupedTickets(ticketsgrouped)
        } else {
            setFilteredGroupedTickets(ticketsgrouped.filter((item) => selectedArea.includes(item.areaname)))
        }
        // setLoading(false)
    }, [ticketsgrouped, selectedArea]); // Run once on component mount

    const checkAndUpdateBoost = useCallback(() => {
        const now = new Date();
        const currentDay = now.toLocaleString("en-US", { weekday: "short" }); // e.g., "Tue"
        const currentTime = now.toTimeString().slice(0, 5); // e.g., "13:31"

        // Filter regulations to determine which ones are boosted
        const boosted = siteRegulations
            .filter((regulation) => {
                const startTime = regulation["Start Time"];
                const endTime = regulation["End Time"];
                return (
                    regulation.Day === currentDay &&
                    currentTime >= startTime &&
                    currentTime <= endTime
                );
            })
            .map((regulation) => ({
                ...regulation,
                Boost: true, // Explicitly set Boost to true
            }));

        setBoostedSites(boosted); // Store only the boosted regulations with Boost: true
    }, [siteRegulations]);

    useEffect(() => {
        const joinedBoosts = Object.values(filteredticketsgrouped).map((ticketGroup) => {
            const matchingRegulation = boostedSites.find(
                (regulation) => regulation.Site === ticketGroup.site
            );
            return {
                ...ticketGroup,
                Boosted: !!matchingRegulation, // true if matching regulation exists
                BoostLevel: matchingRegulation ? matchingRegulation["Boost Level"] : 0, // Boost Level or 0
                BoostTotalScore: ticketGroup.score + (matchingRegulation ? matchingRegulation["Boost Level"] : 0)
            };
        });
        // console.log('boostedSites', boostedSites)
        // console.log(joinedBoosts)

        joinedBoosts.sort((a, b) => b.BoostTotalScore - a.BoostTotalScore);
        setmainSites(joinedBoosts)
        console.log(joinedBoosts)
    }, [filteredticketsgrouped, boostedSites]); // Run once on component mount



    // Function to join sites and tickets based on siteid (tickets) and id (sites)
    const joinTicketsWithRegulations = (tickets, sites, sitesdetailed) => {
        // Create a lookup map from sites using `id` as the key
        const siteLookup = new Map(sites.map(site => [site.id, { sitename: site.sitename, regulations: site.regulations }]));
        const sitesDetailedLookup = new Map(sitesdetailed.map(detail => [detail.id, { areaname: detail.areaname }]));

        // Perform the join
        const joined = tickets.map(ticket => {
            const siteDetails = siteLookup.get(ticket.site); // Find matching site by `siteid`
            const siteDetailedDetails = sitesDetailedLookup.get(ticket.site); // Find matching detailed info by `siteid`
            return {
                ...ticket,
                sitename: siteDetails ? siteDetails.sitename : null, // Add sitename if found
                regulations: siteDetails ? siteDetails.regulations : null, // Add regulations if found
                areaname: siteDetailedDetails ? siteDetailedDetails.areaname : null, // Add regulations if found
            };
        });

        return joined;
    };

    useEffect(() => {
        checkAndUpdateBoost(); // Run once immediately
        const interval = setInterval(checkAndUpdateBoost, 60000); // Every 60 seconds
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [checkAndUpdateBoost]);

    const groupBySite = (data) => {
        const groupedData = data.reduce((acc, item) => {
            // Check if the site already exists in the accumulator
            if (!acc[item.site]) {
                acc[item.site] = {
                    site: item.site,
                    score: 0,
                };
            }
            // Aggregate the counts
            acc[item.site].score += item.decay_weight;

            return acc;
        }, {});

        // Convert back to an array
        const groupedArray = Object.values(groupedData);

        // Sort by score in descending order
        groupedArray.sort((a, b) => b.score - a.score);

        return groupedArray;
    };

    useEffect(() => {
        if (mainSites.length > 0) {
            setLoading(false)
        }
    }, [mainSites])

    // const response = await fetch('https://api.npcpatrolplan.com/api/tickets/grouped/?format=json', {
    //   method: 'GET',
    //   headers: {
    //     'Authorization': `${accessToken}`, // Include the Access Token in the Authorization header
    //   }
    // });

    const periods = [
        { value: "[25,25]", label: "Current" },
        { value: "[0,6]", label: "12am - 6am" },
        { value: "[6,9]", label: "6am - 9am" },
        { value: "[9,12]", label: "9am - 12pm" },
        { value: "[12,17]", label: "12pm - 5pm" },
        { value: "[17,20]", label: "5pm - 8pm" },
        { value: "[20,24]", label: "8pm - 12am" },
        { value: "[0,24]", label: "All day" }
    ];

    const handlePeriodChange = (event) => {
        setLoading(true)
        setSelectedPeriod(event.target.value);
    };

    const handleAreaChange = (event) => {
        setLoading(true)
        setSelectedArea(event.target.value);
        // console.log("Selected Area:", event.target.value);
    };

    return (
        <Authenticator>
            {({ signOut }) => (
                <main className='main-section'>
                    <header className='App-header'>
                        <div className='main-section'>
                            <p>Hi {email}!</p>
                            {/* {error && <p>{error}</p>} */}
                            {location.latitude && location.longitude ? (
                                <div className='current-location-section'>
                                    <p className='current-location-p'>Your current location:</p>
                                    <p className='current-location-p'>Latitude: {location.latitude}</p>
                                    <p className='current-location-p'>Longitude: {location.longitude}</p>
                                </div>
                            ) : (
                                <p>{errorLocation ? `Error: ${errorLocation}` : 'Location not available yet.'}</p>
                            )}
                            <div className='area-dropdown-section'>
                                <label htmlFor="area-dropdown">Select Area: </label>
                                <select
                                    id="area-dropdown"
                                    value={selectedArea}
                                    onChange={handleAreaChange}
                                >
                                    <option value="All">
                                        All
                                    </option>
                                    {uniqueAreas.map((area, index) => (
                                        <option key={index} value={area}>
                                            {area}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="period-dropdown">Select Period: </label>
                                <select
                                    id="period-dropdown"
                                    value={selectedPeriod}
                                    onChange={handlePeriodChange}
                                >
                                    {periods.map((period) => (
                                        <option key={period.label} value={period.value}>
                                            {period.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* Tickets Table */}
                            {loading ? (
                                // Show loader animation while loading
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                    <div style={{
                                        width: '50px',
                                        height: '50px',
                                        border: '5px solid #f3f3f3',
                                        borderTop: '5px solid #3498db',
                                        borderRadius: '50%',
                                        animation: 'spin 1s linear infinite'
                                    }}></div>
                                    <style>
                                        {`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      `}
                                    </style>
                                </div>
                            ) : mainSites.length > 0 ? (
                                // Show table if data is available
                                <table style={{ borderCollapse: 'collapse', marginTop: '20px' }} className='main-table'>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Site Name</th>
                                            {/* <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Reg</th> */}
                                            <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Boost</th>
                                            <th style={{ border: '1px solid #333', padding: '8px', backgroundColor: '#f0f0f0', color: 'black' }}>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mainSites.map((ticket, index) => {
                                            const mainScore = ticket.Boosted
                                                ? ticket.BoostTotalScore.toFixed(2) // Format BoostTotalScore to 2 decimal places
                                                : ticket.score.toFixed(2) // Format score to 2 decimal places
                                            const rowColor = ticket.Boosted
                                                ? 'rgba(46, 204, 113, 0.7)' // Green background
                                                : `rgba(52, 152, 219, ${1 - (0.7 * index) / mainSites.length})`; // Gradient blue
                                            return (

                                                <tr key={ticket.sitename} style={{ backgroundColor: rowColor }}>
                                                    <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>
                                                        <Link
                                                            to={{
                                                                pathname: `/site/${ticket.site}/${ticket.sitename}`, // Dynamic URL with sitename
                                                                search: `?score=${mainScore}`,  // Query parameters
                                                            }}
                                                            style={{
                                                                color: 'white',           // Make the text white
                                                                textDecoration: 'none',   // Remove the underline
                                                            }}
                                                        >
                                                            {ticket.sitename}
                                                        </Link>
                                                    </td>
                                                    {/* <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>{ticket.regulations}</td> */}
                                                    <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>{ticket.Boosted ? 'True: ' + ticket.BoostLevel : ''}</td>
                                                    {/* <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>{ticket.Boosted ? ticket.BoostLevel : ''}</td> */}
                                                    <td style={{ border: '1px solid #f0f0f0', padding: '8px' }}>
                                                        {mainScore}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                // Fallback for no data
                                <p>No tickets available.</p>
                            )}
                        </div>
                        <button
                            onClick={signOut}
                            style={{
                                margin: '20px',
                                fontSize: '0.8rem',
                                padding: '5px 10px',
                                marginTop: '30px',
                                marginBottom: '100px',
                                cursor: 'pointer',     // Makes the cursor a pointer on hover
                                backgroundColor: '#007BFF', // Button background color
                                color: '#fff',         // Text color
                                border: 'none',        // Removes border
                                borderRadius: '5px',   // Rounds the button's corners slightly
                                boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // Adds a subtle shadow
                            }}
                        >
                            Sign Out
                        </button>
                        <button
                            onClick={signOut}
                            style={{
                                position: 'absolute',  // Makes the button's position relative to the nearest positioned ancestor
                                top: '20px',           // Sets the distance from the top edge of the page
                                right: '20px',         // Sets the distance from the right edge of the page
                                fontSize: '0.8rem',    // Adjusts font size
                                padding: '5px 10px',   // Padding for button content
                                cursor: 'pointer',     // Makes the cursor a pointer on hover
                                backgroundColor: '#007BFF', // Button background color
                                color: '#fff',         // Text color
                                border: 'none',        // Removes border
                                borderRadius: '5px',   // Rounds the button's corners slightly
                                boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // Adds a subtle shadow
                            }}
                        >
                            Sign Out
                        </button>
                    </header>
                </main>
            )}
        </Authenticator>
    );
}

export default withAuthenticator(Home);