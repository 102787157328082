import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../components/Home";
import SitePage from "../components/SitePage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />, // Main wrapper component
    children: [
      { path: "", element: <Home /> }, // Home route
      { path: "site/:siteid/:sitename", element: <SitePage /> }, // Home route
    ],
  },
]);